<template>
  <MainLayout>
    <template v-slot:block>
      {{
        sourceObject.results
          ? sourceObject.results.is_active === false
            ? "Заблокирован -"
            : ""
          : ""
      }}
    </template>
    <template v-slot:navbar-title
      >{{ sourceObject.results ? sourceObject.results.name : "" }}
    </template>
    <template v-slot:navbar-btn-wrap>
      <BlockPopup
        v-if="sourceObject.results && sourceObject.results.is_active"
        name="Контроллер"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :block-function="banController"
      />
      <UnBlockPopup
        v-if="!(sourceObject.results && sourceObject.results.is_active)"
        name="Контроллер"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :un-block-function="unBlockController"
      />
      <DeletePopup
        v-if="
          $store.getters.getCurrentRole !== 40 &&
            $store.getters.getCurrentRole !== 50
        "
        name="Контроллер"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :delete-function="deleteController"
      />
      <DeletePopup
        v-if="$store.getters.getCurrentRole === 70"
        name="контроллер безвозвратно"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :delete-function="deletePermanentlyController"
        :description="description"
      />
      <router-link
        v-if="
          urlQuery &&
            urlQuery.id &&
            $store.getters.getCurrentRole !== 40 &&
            $store.getters.getCurrentRole !== 50
        "
        :to="`/controllersEdit/` + urlQuery.id"
        class="btn btn-edit btn_color_grey"
        >редактировать
      </router-link>
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:info>
        <div class="keep__title"></div>

        <Basic_Win8_Style
          v-if="sourceObject"
          class="table_left-align"
          :table-data="tableData"
          :loading="isDateLoaded"
        >
          <template #item.0.value="{item}">
            <div class="wrapper">
              <div class="title">
                {{ item.title.value }}
              </div>
              <br />

              <div class="" style="display: flex; ">
                <span
                  style="text-align:initial; padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.text }}:</span
                >
                <span style="color: #505a6d">{{
                  !item.value.status.connected &&
                  item.value.status.last_time_online
                    ? moment(item.value.status.last_time_online * 1000).format(
                        "DD.MM.YYYY HH:mm"
                      )
                    : ""
                }}</span>
                <span
                  style="padding: 0 10px; margin: 0 15px;  color: #ffffff; border-radius: 10px"
                  :style="{
                    'background-color': item.value.status.connected
                      ? '#95C23D'
                      : item.value.status.last_time_online
                      ? '#EB5C6D'
                      : '#D6D9E1'
                  }"
                  >{{
                    item.value.status.connected
                      ? "В сети"
                      : item.value.status.last_time_online
                      ? "Не в сети"
                      : "Нет"
                  }}</span
                >
                <br />
              </div>

              <br />
              <div style="display: flex">
                <span
                  style="max-width: 50%; text-align:initial; padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.type }}:</span
                >
                <div
                  style="padding: 0 10px; margin: 0 15px 0 0; color: #ffffff; border-radius: 10px; display: flex; "
                  :style="{
                    'background-color':
                      item.value.logs == true ? '#95C23D' : '#EB5C6D'
                  }"
                >
                  {{ item.value.logs == true ? "Подключено" : "Не подключено" }}
                </div>
              </div>
              <br />
            </div>
          </template>

          <template #item.1.value="{item}">
            <div class="wrapper">
              <div class="title">
                {{ item.title.value }}
              </div>
              <br />

              <div class="" style="display: flex ">
                <span
                  style="text-align:initial; padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.text }}:</span
                >
                <span
                  style="padding: 0 15px 0 0; text-align:initial; color: #505a6d;"
                  >{{ item.value.id }}</span
                >
              </div>

              <br />
              <div style=" display: flex ">
                <span
                  style="text-align:initial; padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.type }}:</span
                >
                <span
                  style="padding: 0 15px 0 0;;text-align:initial; color: #505a6d;"
                  >{{ item.value.type }}</span
                >
              </div>
              <br />
            </div>
          </template>

          <template #item.2.value="{item}">
            <div class="wrapper">
              <div class="title">
                {{ item.title.value }}
              </div>
              <br />
              <div class="" style="display: flex;">
                <span
                  style="text-align:initial; padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >Версия чипа:</span
                >
                <span
                  style="padding: 0 15px 0 0; text-align:initial; color: #505a6d; "
                  >{{ item.value.esp_board_version }}</span
                >
                <span
                  style="text-align:initial; padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.esp }}:</span
                >

                <span
                  style="padding: 0 15px 0 0; text-align:initial; color: #505a6d; width: 100px"
                  >{{ item.value.esp }}</span
                >
              </div>

              <br />
              <div class="" style="display: flex;">
                <span
                  style="text-align:initial; padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >Версия чипа:</span
                >
                <span
                  style="padding: 0 15px 0 0; text-align:initial; color: #505a6d; "
                  >{{ item.value.nrf_board_version }}</span
                >
                <span
                  style="text-align:initial; padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.nrf }}:</span
                >
                <span
                  style="padding: 0 15px 0 0; text-align:initial; color: #505a6d; width: 100px"
                  >{{ item.value.nrf }}</span
                >
              </div>
              <br />
            </div>
          </template>

          <template #item.3.value="{item}">
            <div class="wrapper">
              <div class="title">
                {{ item.title.value }}
              </div>
              <br />
              <div class="" style=" display: flex">
                <span
                  style="text-align:initial; padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.primary }}:</span
                >
                <span
                  style="padding: 0 15px 0 0;text-align:initial; color: #505a6d; "
                  >{{ item.value.primary_mac }}</span
                >
              </div>

              <br />
              <div class="" style="display: flex">
                <span
                  style="text-align:initial; padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.secondary }}:</span
                >
                <span
                  style="padding: 0 15px 0 0; text-align:initial; color: #505a6d; "
                  >{{ item.value.secondary_mac }}</span
                >
              </div>
              <br />
            </div>
          </template>

          <template #item.4.value="{item}">
            <div class="wrapper">
              <div class="title">
                {{ item.title.value }}
              </div>
              <br />
              <div class="" style=" display: flex">
                <span
                  style="text-align:initial; padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.organization }}:</span
                >
                <span
                  style=" padding: 0 15px 0 0; text-align:initial; color: #505a6d; "
                  >{{ item.value.organizationId }}</span
                >
              </div>

              <br />
              <div class="" style=" display: flex">
                <span
                  style="text-align:initial; padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.adress }}:</span
                >
                <span
                  style="padding: 0 15px 0 0;text-align:initial; color: #505a6d; "
                  >{{ item.value.organizationAdress }}</span
                >
              </div>
              <br />
            </div>
          </template>
          <template #item.5.value="{item}">
            <div class="wrapper">
              <div class="title">
                {{ item.title.value }}
              </div>
              <br />
              <div class="" style=" display: flex">
                <span
                  style="padding: 0 15px; text-align: initial; color: #505a6d; font-weight: 500;"
                  >{{ item.text.organization }}:</span
                >
                <span
                  style="padding: 0 15px 0 0; text-align:initial; color: #505a6d; "
                  >{{ item.value.organizationId }}</span
                >
              </div>

              <br />
              <div class="" style="display: flex">
                <span
                  style="    min-inline-size: fit-content;text-align:initial;padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.adress }}:</span
                >
                <span
                  style="padding: 0 15px 0 0; width:70%;text-align:initial; color: #505a6d; "
                  >{{ item.value.organizationAdress }}</span
                >
              </div>
              <br />
            </div>
          </template>

          <template
            v-if="$store.getters.getCurrentRole !== 30"
            #item.6.value="{item}"
          >
            <div class="wrapper">
              <div class="title">
                {{ item.title.value }}
              </div>
              <br />
              <div style="display: flex;">
                <span
                  style="text-align:initial;padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.company }}</span
                >
                <span
                  style="padding: 0 15px 0 0;text-align:initial; color: #505a6d; "
                  >{{ item.value }}</span
                >
              </div>

              <br />
            </div>
          </template>
          <template
            v-if="
              $store.getters.getCurrentRole !== 60 &&
                $store.getters.getCurrentRole !== 30
            "
            #item.7.value="{item}"
          >
            <div class="wrapper">
              <div class="title">
                {{ item.title.value }}
              </div>
              <br />
              <div style="display: flex;">
                <span
                  style="text-align:initial;padding: 0 15px;  color: #505a6d; font-weight: 500; "
                  >{{ item.text.diller }}</span
                >
                <span
                  style="padding: 0 15px 0 0;text-align:initial; color: #505a6d; "
                  >{{ item.value }}</span
                >
              </div>

              <br />
            </div>
          </template>
        </Basic_Win8_Style>
      </template>
      <template v-slot:users_group>
        <ControllersUsersGroup />
      </template>
      <template v-slot:controllers_settings>
        <ControllersSettings
          :controller-info="controllerInfo"
          :ws-messages="wsMessages"
        />
      </template>
      <template v-slot:controllers_firmwares>
        <ControllersFirmwares :controller-info="controllerInfo" />
      </template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import Basic_Win8_Style from "@/components/tables/Basic_Win8_Style";
import * as query from "@/helpers/query";
import ControllersUsersGroup from "@/components/info/controllers/ControllersUsersGroup";
import ControllersSettings from "@/components/info/controllers/ControllersSettings";
import ControllersFirmwares from "@/components/info/controllers/ControllersFirmwares";
import slideTabsControllers from "@/helpers/slideTabs/controllers";
import BlockPopup from "@/components/popup/BlockPopup";
import DeletePopup from "@/components/popup/DeletePopup";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";
import UnBlockPopup from "@/components/popup/UnBlockPopup";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import { getToken } from "@/helpers/jwtHelper";

export default {
  name: "ControllersInfosPages",
  components: {
    UnBlockPopup,
    DeletePopup,
    BlockPopup,
    Basic_Win8_Style,
    SlideLayout,
    MainLayout,
    ControllersUsersGroup,
    ControllersSettings,
    ControllersFirmwares
  },
  data: () => ({
    type: "controllers",
    queries: query.organizations,
    moment: moment,
    wsConnection: null, // подключение по веб сокету к контроллеру
    wsStatus: false, // true || false
    wsMessages: []
  }),
  computed: {
    description() {
      return `Контроллер "${this.sourceObject.results.name}" будет удален безвозвратно. Вы действительно хотите продолжить?`;
    },
    tableData() {
      if (this.sourceObject.results)
        return [
          {
            title: {
              value: "Статус"
            },
            text: {
              text: "Был в сети",
              type: "Подключение к логам"
            },
            value: {
              status: {
                connected: this.sourceObject.results.connected,
                last_time_online: this.sourceObject.results.last_time_online
              },
              logs: this.wsStatus
            }
          },
          {
            title: {
              value: "Контроллер"
            },
            text: {
              text: "ID контроллера",
              type: "Тип контролллера"
            },
            value: {
              id: this.sourceObject.results.id,
              type:
                dictionariesHelper.controllersType[
                  this.sourceObject.results.type
                ]
            }
          },
          {
            title: {
              value: "Версия прошивки"
            },
            text: {
              esp: "ESP",
              nrf: "NRF"
            },
            value: {
              esp: this.sourceObject.results.primary_firmware_version
                ? this.sourceObject.results.primary_firmware_version
                : "-",
              nrf: this.sourceObject.results.secondary_firmware_version
                ? this.sourceObject.results.secondary_firmware_version
                : "-",
              esp_board_version: this.sourceObject.results
                .primary_firmware_board_version
                ? this.sourceObject.results.primary_firmware_board_version
                : "-",
              nrf_board_version: this.sourceObject.results
                .secondary_firmware_board_version
                ? this.sourceObject.results.secondary_firmware_board_version
                : "-"
            }
          },
          {
            title: {
              value: "MAC"
            },
            text: {
              primary: "1",
              secondary: "2"
            },
            value: {
              primary_mac: this.sourceObject.results.primary_mac
                ? this.sourceObject.results.primary_mac
                : "-",
              secondary_mac: this.sourceObject.results.secondary_mac
                ? this.sourceObject.results.secondary_mac
                : "-"
            }
          },
          {
            title: {
              value: "Объект"
            },
            text: {
              organization:
                dictionariesHelper.objectType[
                  this.sourceObject.results.object_type
                ],
              adress: "Адрес"
            },
            value: {
              organizationId: this.sourceObject.results.object_name
                ? this.sourceObject.results.object_name
                : "-",
              organizationAdress: this.sourceObject.results.object_address
                ? this.sourceObject.results.object_address
                : "-"
            }
          },
          {
            title: {
              value: "Обслуживающая организация"
            },
            text: {
              organization: "Название",
              adress: "Техник/телефон"
            },

            value: {
              organizationId: this.sourceObject.results.object_service
                ? this.sourceObject.results.object_service.name
                : "-",
              organizationAdress: "-"
            }
          },
          {
            title: {
              value: "Компания"
            },
            text: {
              company: "Название:"
            },
            value: this.sourceObject.results.organization_name
              ? this.sourceObject.results.organization_name
              : ""
          },
          {
            title: {
              value: "Дилер"
            },
            text: {
              diller: "Название:"
            },
            value: this.sourceObject.results.organization_diller
              ? this.sourceObject.results.organization_diller.name
              : ""
          }

          // {
          //   text: "Тип контролллера",
          //   value:
          //       dictionariesHelper.controllersType[this.sourceObject.results.type]
          // },
          // {
          //   text: "Доступ в сеть / был в сети",
          //   value: {
          //     connected: this.sourceObject.results.connected,
          //     last_time_online: this.sourceObject.results.last_time_online
          //   }
          // },
          // {
          //   text: "Подключение к логам",
          //   value: this.wsStatus
          // },
          // {
          //   text: "Тип контролллера",
          //   value:
          //     dictionariesHelper.controllersType[this.sourceObject.results.type]
          // },
          // {
          //   text: "Название контроллера",
          //   value: this.sourceObject.results.name
          // }
          // {
          //   text: "Объект",
          //   value: this.sourceObject.results.object_name
          // },
          // {
          //   text: "Адрес контроллера",
          //   value: this.sourceObject.results.object_address
          // },
          // {
          //   text: "Дилер",
          //   value: this.sourceObject.results.organization_diller
          //       ? this.sourceObject.results.organization_diller.name
          //       : ""
          // },
          // {
          //   text: "Открытый ключ",
          //   value: this.sourceObject.results.public_key
          //       ? this.sourceObject.results.public_key
          //           .replace("-----BEGIN PUBLIC KEY-----", "")
          //           .replace("-----END PUBLIC KEY-----", "")
          //       : ""
          // },
          // {
          //   text: "Мак адрес",
          //   value: this.sourceObject.results.primary_mac
          // },
          // {
          //   text: "Компания",
          //   value: this.sourceObject.results.organization_name
          // },
          // {
          //   text: "Обслуживающая организация",
          //   value: this.sourceObject.results.object_service
          //       ? this.sourceObject.results.object_service.name
          //       : ""
          // },
          // {
          //   text: "Техник / Телефон",
          //   value: "-"
          // },
          // {
          //   text: "Версия ПО",
          //   value: this.sourceObject.results.primary_firmware_version
          // }
        ];
      else return [];
    },
    sourceObject() {
      return this.$store.getters.getControllerInfo;
    },
    controllerInfo() {
      return this.$store.getters.getControllerInfoById;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    roles() {
      return this.$store.getters.getRoles;
    },
    currentUserGroupId() {
      return this.$store.getters.getCurrentUserGroupId;
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    slideTabs() {
      return slideTabsControllers
        .filter(el => el.userRole.includes(this.$store.getters.getCurrentRole))
        .map(slide => {
          switch (slide.value) {
            case "info": {
              return {
                ...slide,
                count: this.sourceObject.results?.access_group_count ? "" : ""
              };
            }
            default: {
              return slide;
            }
          }
        });
    }
  },
  methods: {
    deletePermanentlyController() {
      this.$store
        .dispatch("deletePermanentlyController", { id: this.urlQuery.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.controller)
          );
        });
    },
    deleteController() {
      this.$store
        .dispatch("deleteController", { id: this.urlQuery.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.controller)
          );
        });
    },
    banController() {
      this.$store
        .dispatch("banController", { id: this.urlQuery.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.ban(successMessageDictionary.controller)
          );
        });
    },
    unBlockController() {
      this.$store
        .dispatch("unBlockController", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.unBan(successMessageDictionary.controller)
          );
        });
    }
  },

  created() {
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query, type: this.type }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchControllersInfo").finally(() => {
      this.isDateLoaded = false;
    });
    this.$store
      .dispatch("getControllerSettings")
      .then(() => {
        const token = getToken().access || null;
        this.$cookies.set("X-Authorization", `${token}`);
      })
      .then(() => {
        return new Promise(resolve => {
          resolve(this.$store.dispatch("wsControllerConnection"));
        });
      })
      .then(webSocket => {
        const self = this;
        this.wsConnection = webSocket;
        this.wsConnection.onopen = function() {
          self.$cookies.remove("X-Authorization");
          self.wsStatus = true;
        };
        this.wsConnection.onerror = function(event) {
          console.log("onerror", event);
          self.wsStatus = false;
        };
        this.wsConnection.onclose = function(event) {
          console.log("close", event);
          self.wsStatus = false;
        };
        this.wsConnection.onmessage = function(event) {
          self.wsMessages.push(JSON.parse(event.data));
        };

        // 0 – «CONNECTING»: соединение ещё не установлено,
        // 1 – «OPEN»: обмен данными,
        // 2 – «CLOSING»: соединение закрывается,
        // 3 – «CLOSED»: соединение закрыто.
      });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchControllersInfo").finally(() => {
        this.isDateLoaded = false;
      });
      this.$store.dispatch("getControllerSettings");
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearOrganizationPage").then(() => next());
  }
};
</script>

<style scoped>
.title {
  color: #505a6d;
  -webkit-box-shadow: 0 1px 3px rgba(37, 65, 106, 0.1) !important;
  box-shadow: 0 1px 0px rgba(37, 65, 106, 0.1) !important;
  margin: 12px 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: initial;
}
</style>
