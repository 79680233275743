<template>
  <div class="list__content-adding list__content-setting">
    <div class="title card">
      <div class="title__avatar title__avatar-setting"></div>
      <p class="title__text">Обновление ПО контроллера</p>
    </div>

    <div class="data card">
      <p class="data__text">Обновление Основной прошивки</p>
    </div>
    <div class="form-wrap">
      <ValidationForm formClass="form card" v-slot:default="{ handleSubmit }">
        <div>
          <p class="card__name">Текущая версия</p>
          <p class="card__number">
            {{ controllerInfo.primary_firmware_version }}
          </p>
        </div>
        <div class="inputs">
          <div class="input-field">
            <ValidationSelectField
              class="validation-select-field"
              label="Чип прошивки"
              :items="chipList"
              v-model="primary_firmware.chip"
            ></ValidationSelectField>
          </div>

          <ValidationInputField
            rules="required"
            label="Версия ПО"
            v-model="primary_firmware.version"
            validate-name="Версия ПО"
          ></ValidationInputField>

          <ValidationFileField
            v-model="primary_firmware.file"
            :rules="'required'"
            label="Файл"
          />

          <button
            @click.prevent="handleSubmit(updatePrimaryFirmware)"
            class="btn btn_color_green grid-column_1 flex-left list__btn-content-setting"
          >
            Обновить
          </button>
        </div>
      </ValidationForm>
    </div>

    <div class="data card">
      <p class="data__text">Обновление secondary_firmware</p>
    </div>
    <div class="form-wrap">
      <ValidationForm formClass="form card" v-slot:default="{ handleSubmit }">
        <div>
          <p class="card__name">Текущая версия</p>
          <p class="card__number">
            {{ controllerInfo.secondary_firmware_version }}
          </p>
        </div>
        <div class="inputs">
          <div class="input-field">
            <ValidationSelectField
              class="validation-select-field"
              label="Чип прошивки"
              :items="chipList"
              v-model="secondary_firmware.chip"
            ></ValidationSelectField>
          </div>

          <ValidationInputField
            rules="required"
            label="Версия ПО"
            v-model="secondary_firmware.version"
            validate-name="Версия ПО"
          ></ValidationInputField>

          <ValidationFileField
            v-model="secondary_firmware.file"
            :rules="'required'"
            label="Файл"
          />

          <button
            @click.prevent="handleSubmit(updateSecondaryFirmware)"
            class="btn btn_color_green grid-column_1 flex-left list__btn-content-setting"
          >
            Обновить
          </button>
        </div>
      </ValidationForm>
    </div>
  </div>
</template>

<script>
import ValidationForm from "@/components/ValidationField/ValidationForm";
import ValidationFileField from "@/components/ValidationField/ValidationFileField";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";

import {
  createControllerFirmwaresRequest,
  updateControllersPrimaryFirmware,
  updateControllersSecondaryFirmware
} from "@/helpers/api/controller-firmwares";

export default {
  props: ["controllerInfo"],
  components: {
    ValidationForm,
    ValidationFileField,
    ValidationInputField,
    ValidationSelectField
  },
  data() {
    return {
      chipList: ["ESP", "NRF"],
      primary_firmware: {
        chip: null,
        version: null,
        file: null
      },
      secondary_firmware: {
        chip: null,
        version: null,
        file: null
      }
    };
  },
  mounted() {
    //
  },
  methods: {
    async updatePrimaryFirmware() {
      let result = await this.createFirmware(this.primary_firmware);
      let data = { primary_firmware: result.data.id };

      updateControllersPrimaryFirmware({
        id: this.controllerInfo.id,
        data
      });
    },
    async updateSecondaryFirmware() {
      let result = await this.createFirmware(this.secondary_firmware);
      let data = { secondary_firmware: result.data.id };

      updateControllersSecondaryFirmware({
        id: this.controllerInfo.id,
        data
      });
      //     .then(response => {
      //   alert("ПО обновлено.");
      // });
    },
    async createFirmware(firmware) {
      let { chip, version, file } = firmware;
      const formData = new FormData();

      formData.append("chip", chip);
      formData.append("version", version);
      formData.append("file", file);
      formData.append("created_by", this.$store.getters.getUserId);

      return await createControllerFirmwaresRequest({
        data: formData
      });
    }
  }
};
</script>

<style>
.settings__input {
  display: none;
}

.validation-input-field span {
  width: 100%;
}

.validation-input-field div {
  box-shadow: unset;
  background: unset;
}

.validation-input-field .v-input__slot {
  background: unset;
  box-shadow: none;
  padding: 0 !important;
  background: unset !important;
}

.validation-input-field .v-input__slot fieldset {
  border: unset;
}

.validation-input-field .v-text-field__details,
.validation-select-field .v-text-field__details {
  position: absolute;
  bottom: -10px;
  left: 0;
}

.validation-input-field .v-label,
.validation-select-field .v-label {
  padding: 0 !important;
  color: #6d778e !important;
}

.validation-select-field {
  width: 100%;
}

.validation-select-field .v-select__slot {
  padding: 0;
}

.validation-input-field .v-text-field--outlined.v-input--dense .v-label--active,
.validation-select-field
  .v-text-field--outlined.v-input--dense
  .v-label--active,
.validation-select-field .v-label {
  transform: translateY(-13px);
}
</style>
