const slideTabsControllers = [
  {
    text: "Общая информация",
    value: "info",
    icon: "i",
    count: null,
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },

  {
    text: "Настройки контроллера",
    value: "controllers_settings",
    icon: "",
    // count: 127,
    userRole: [40, 50, 70]
  }
];

export default slideTabsControllers;
