<template>
  <div>
    <v-subheader style="position:relative; top: -20px; left: -10px">{{
      label
    }}</v-subheader>
    <v-slider
      :value="value"
      :disabled="disabled"
      class="align-center"
      :max="maxValue"
      :min="minValue"
      hide-details
      :color="colorStart"
      :track-color="colorEnd"
      :thumb-color="colorMark"
      @input="$emit('input', $event)"
      thumb-label="always"
    >
      <template v-slot:append>
        <v-text-field
          :value="value"
          :disabled="disabled"
          dense
          filled
          outlined
          class="mt-0 pt-0 custom-input-field"
          hide-details
          single-line
          type="number"
          @input="$emit('input', $event)"
          style="width: 50px"
        ></v-text-field>
      </template>
    </v-slider>
  </div>
</template>

<script>
export default {
  name: "TextareaField",
  props: {
    disabled: {
      type: Boolean
    },
    label: {
      type: String
    },
    minValue: {
      type: String,
      required: true
    },
    maxValue: {
      type: String,
      required: true
    },
    errors: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String]
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  data() {
    return {
      colorMark: "#95c23d",
      colorStart: "#95c23d",
      colorEnd: "#6d778e"
    };
  }
};
</script>
