<template>
  <div class="list__content-adding list__content-setting">
    <div class="title card">
      <div class="title__avatar title__avatar-setting"></div>
      <p class="title__text">Настройки контроллера</p>
      <p class="title__code">{{ controllerSettings.primary_mac }}</p>
    </div>
    <v-expansion-panels>
      <v-expansion-panel class="pt-3 pb-3">
        <v-expansion-panel-header class="data__text">
          Логи контроллера
          {{
            wsMessages.length > 0
              ? ` (Последний: ${wsMessages[wsMessages.length - 1].text +
                  (wsMessages[wsMessages.length - 1].status != null ||
                  wsMessages[wsMessages.length - 1].status != "null" ||
                  wsMessages[wsMessages.length - 1].status != undefined ||
                  wsMessages[wsMessages.length - 1].status != ""
                    ? wsMessages[wsMessages.length - 1].status == "complete"
                      ? " - Завершено"
                      : wsMessages[wsMessages.length - 1].status == "init"
                      ? " - Начато"
                      : ""
                    : "")})`
              : ""
          }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ol v-if="wsMessages.length > 0">
            <li
              v-for="(message, i) in wsMessages"
              :key="i"
              style="font-size: 14px; max-width: max-content; padding: 0px 8px; border-radius: 10px; margin-top: 3px;"
              :style="{
                'background-color':
                  message.status == 'complete' ? '#95C23D' : '#c7c7c7'
              }"
            >
              {{
                message.text +
                  (message.status != null ||
                  message.status != "null" ||
                  message.status != undefined ||
                  message.status != ""
                    ? message.status == "complete"
                      ? " - Завершено"
                      : message.status == "init"
                      ? " - Начато"
                      : ""
                    : "") || JSON.stringify(message)
              }}
            </li>
          </ol>
          <div style="font-size: 14px;" v-else>Нет данных</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="data card">
      <p class="data__text">Настройки Bluetooth</p>
    </div>
    <div class="form-wrap">
      <ValidationForm formClass="form card" v-slot:default="{ handleSubmit }">
        <div>
          <div class="form__avatar form__avatar-mac"></div>
        </div>
        <div class="inputs">
          <ValidationCustomizedInputField
            v-model="controllerSettings.primary_mac"
            type="text"
            label="MAC Вход"
            mask="XX:XX:XX:XX:XX:XX"
            required
            rules="required"
          />
          <ValidationCustomizedInputField
            v-model="controllerSettings.secondary_mac"
            type="text"
            label="MAC Выход"
            mask="XX:XX:XX:XX:XX:XX"
          />
          <div class="input-field">
            <ValidationSelectField
              class="validation-select-field"
              label="Разрешенное направление прохода"
              :items="directions"
              v-model="controllerSettings.directions"
              base-select-field
            ></ValidationSelectField>
          </div>
          <div class="switcher">
            <input
              v-model="controllerSettings.hands_free"
              type="checkbox"
              class="checkbox_none"
              id="switcher"
            />
            <label class="switcher__inner" for="switcher">
              <div class="switcher__point" />
            </label>
            <p class="switcher__text">Свободные руки</p>
          </div>
          <ValidationSlider
            v-if="controllerSettings.hands_free"
            label="Оптимальный уровень сигнала"
            v-model="controllerSettings.trigger_level"
            rules="required|max_value:120"
            minValue="0"
            maxValue="120"
          />
          <button
            @click.prevent="handleSubmit(updateBluetoothSection)"
            class="btn btn_color_green grid-column_1 flex-left list__btn-content-setting"
          >
            Применить настройки
          </button>
        </div>
      </ValidationForm>
    </div>
    <div class="data card">
      <p class="data__text">Обновление прошивки основного чипа</p>
    </div>
    <div class="form-wrap">
      <ValidationForm formClass="form card" v-slot:default="{ handleSubmit }">
        <div>
          <div class="form__avatar form__avatar-lock"></div>
        </div>
        <div class="inputs">
          <ValidationAutocompleteField
            class="validation-select-field"
            label="Версия прошивки"
            :searchFunction="searchPrimeFirm"
            v-model="controllerSettings.primary_firmware"
            required
            base-select-field
          />
          <ValidationInputField
            label="Версия чипа"
            rules="max_value:255"
            type="number"
            required
            v-model="controllerSettings.primary_firmware_board_version"
            :disabled="true"
          />
          <button
            @click.prevent="handleSubmit(updatePrimeFirmware)"
            class="btn btn_color_green grid-column_1 flex-left list__btn-content-setting"
          >
            активировать настройки
          </button>
        </div>
      </ValidationForm>
    </div>
    <div class="data card">
      <p class="data__text">Обновление прошивки вторичного чипа</p>
    </div>
    <div class="form-wrap">
      <ValidationForm formClass="form card" v-slot:default="{ handleSubmit }">
        <div>
          <div class="form__avatar form__avatar-lock"></div>
        </div>
        <div class="inputs">
          <ValidationAutocompleteField
            class="validation-select-field"
            label="Версия прошивки"
            :searchFunction="searchSecondFirm"
            v-model="controllerSettings.secondary_firmware"
            required
            base-select-field
          />
          <ValidationInputField
            label="Версия чипа"
            rules="max_value:255"
            type="number"
            required
            v-model="controllerSettings.secondary_firmware_board_version"
            :disabled="true"
          />
          <button
            @click.prevent="handleSubmit(updateSecondaryFirmware)"
            class="btn btn_color_green grid-column_1 flex-left list__btn-content-setting"
          >
            активировать настройки
          </button>
        </div>
      </ValidationForm>
    </div>

    <div class="data card">
      <p class="data__text">Настройки замка</p>
    </div>
    <div class="form-wrap">
      <ValidationForm formClass="form card" v-slot:default="{ handleSubmit }">
        <div>
          <div class="form__avatar form__avatar-lock"></div>
        </div>
        <div class="inputs">
          <div class="input-field">
            <ValidationSelectField
              class="validation-select-field"
              label="Тип замка"
              :items="[
                { text: 'Прямое подключение', value: 0 },
                { text: 'Обратное подключение', value: 1 }
              ]"
              v-model="controllerSettings.lock_connection_type"
              required
              base-select-field
            />
          </div>
          <ValidationInputField
            label="Время открытия"
            rules="required|max_value:255"
            type="number"
            required
            v-model="controllerSettings.lock_door_opened_time"
          />
          <button
            @click.prevent="handleSubmit(updateLockSection)"
            class="btn btn_color_green grid-column_1 flex-left list__btn-content-setting"
          >
            активировать настройки
          </button>
        </div>
      </ValidationForm>
    </div>
    <div class="data card">
      <p class="data__text">Настройки считывателей</p>
    </div>
    <div class="form-wrap">
      <ValidationForm formClass="form card" v-slot:default="{ handleSubmit }">
        <div>
          <div class="form__avatar form__avatar-QR"></div>
        </div>
        <div class="inputs">
          <ValidationSelectField
            class="validation-select-field"
            label="Выбор интерфейса #1"
            :items="[
              { value: 0, text: 'TM' },
              { value: 1, text: 'Wiegand' },
              { value: 2, text: 'Off' }
            ]"
            v-model="controllerSettings.wiegand1"
            base-select-field
          />
          <ValidationSelectField
            :disabled="controllerSettings.wiegand1 == 2"
            class="validation-select-field"
            label="Выбор направления интерфейса #1"
            :items="[
              { value: 0, text: 'Forward' },
              { value: 1, text: 'Backward' }
            ]"
            v-model="controllerSettings.wiegand1_direction"
            base-select-field
          />
          <div></div>
          <!-- Заглушка -->
          <!-- <ValidationSelectField
            disabled
            class="validation-select-field"
            label="RS1"
            :items="[
              { value: 0, text: 'RS232' },
              { value: 1, text: 'RS485' },
              { value: 2, text: 'Off' }
            ]"
            v-model="controllerSettings.rs1"
            base-select-field
          /> -->
          <ValidationSelectField
            class="validation-select-field"
            label="Активация интерфейса Wiegand"
            :items="[
              { value: 0, text: 'On' },
              { value: 1, text: 'Off' }
            ]"
            v-model="controllerSettings.wiegand2"
            base-select-field
          />
          <ValidationSelectField
            :disabled="controllerSettings.wiegand2 == 1"
            class="validation-select-field"
            label="Выбор направления интерфейса #2"
            :items="[
              { value: 0, text: 'Forward' },
              { value: 1, text: 'Backward' }
            ]"
            v-model="controllerSettings.wiegand2_direction"
            base-select-field
          />
          <div></div>
          <!-- Заглушка -->
          <!-- <ValidationSelectField
            disabled
            class="validation-select-field"
            label="RS2"
            :items="[
              { value: 0, text: 'On' },
              { value: 1, text: 'Off' }
            ]"
            v-model="controllerSettings.rs2"
            base-select-field
          /> -->
          <button
            @click.prevent="handleSubmit(updateInterfaceSection)"
            class="btn btn_color_green grid-column_1 flex-left list__btn-content-setting"
          >
            активировать настройки
          </button>
        </div>
      </ValidationForm>
    </div>
    <div class="data card">
      <p class="data__text">Настройки портов БУ</p>
    </div>

    <div
      v-for="item in gpioConfigSettings"
      v-bind:key="item.number"
      class="form-wrap"
    >
      <ValidationForm formClass="form card" v-slot:default="{ handleSubmit }">
        <div>
          <p class="card__name">Порт</p>
          <p class="card__number">№{{ item.number }}</p>
        </div>
        <div class="inputs">
          <div class="input-field">
            <ValidationSelectField
              v-model="item.logic"
              class="validation-select-field"
              label="Функции порта"
              :items="portsList(item)"
              base-select-field
            />
          </div>
          <div>
            <p class="list__radio-title">Активный уровень</p>
            <div class="list__radio-wrap">
              <label class="radio-wrap">
                <input
                  v-model="item.level_of_one"
                  type="radio"
                  class="radio_none"
                  :value="1"
                />
                <div class="radio"></div>
                <p class="radio_text">
                  {{ !checkActiveLevel(item) ? "Высокий" : "При нажатии" }}
                </p>
              </label>
              <label class="radio-wrap">
                <input
                  v-model="item.level_of_one"
                  type="radio"
                  class="radio_none"
                  :value="0"
                />
                <div class="radio"></div>
                <p class="radio_text">
                  {{ !checkActiveLevel(item) ? "Низкий" : "При отпускании" }}
                </p>
              </label>
            </div>
          </div>
          <div class="input-field">
            <ValidationSelectField
              v-model="item.signal_type"
              class="validation-select-field"
              label="Тип сигнала"
              :items="
                item.logic == 8
                  ? gpioParams.signal_type_2
                  : item.logic == 4 || item.logic == 3
                  ? gpioParams.signal_type_3
                  : gpioParams.signal_type
              "
              base-select-field
            />
          </div>

          <button
            @click.prevent="handleSubmit(updateGpio(item))"
            class="btn btn_color_green grid-column_1 flex-left list__btn-content-setting"
          >
            активировать настройки
          </button>
        </div>
      </ValidationForm>
    </div>
    <v-snackbar bottom right v-model="status">
      <div style="background-color: #ffffff; color: #95c23d;">
        Ваши данные сохранены
      </div>
    </v-snackbar>
    <v-expansion-panels>
      <v-expansion-panel class="pt-3 pb-3">
        <v-expansion-panel-header class="data__text">
          Обновление данных
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="navbar__btn-wrap">
            <button
              @click="uploadUserAccesses"
              class="btn btn_color_green btn-change"
            >
              Обновить пользователей
            </button>
            <button
              @click="uploadCardsAccesses"
              class="btn btn_color_green btn-change"
            >
              Обновить карты
            </button>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels>
      <v-expansion-panel class="pt-3 pb-3">
        <v-expansion-panel-header class="data__text">
          Кеш карт
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="navbar__btn-wrap ">
            <div class="inputs hash">
              <ValidationInputField
                label="Остаток времени приложения, сек"
                :disabled="true"
                required
                v-model="controllerHash.app_ttl"
              />
              <ValidationInputField
                label="Остаток времени карт, сек"
                :disabled="true"
                required
                v-model="controllerHash.card_ttl"
              />
              <button
                @click="resetHash"
                class="btn btn_color_green btn-change hashResolve"
              >
                Сброс кэша
              </button>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import ValidationCustomizedInputField from "@/components/ValidationField/ValidationCustomizedInputField";
import ValidationForm from "@/components/ValidationField/ValidationForm";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import ValidationSlider from "@/components/ValidationField/ValidationSlider";

import {
  patchControllerByIdRequest,
  setExternalInterfacesRequest,
  setGpioConfigRequest,
  setLockConfigRequest
} from "@/helpers/api/controllers";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import {
  getControllerFirmwaresByIdsRequest,
  updateControllersPrimaryFirmware,
  updateControllersSecondaryFirmware
} from "../../../helpers/api/controller-firmwares";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  props: ["controllerInfo", "wsMessages"],
  components: {
    ValidationAutocompleteField,
    ValidationInputField,
    ValidationSelectField,
    ValidationForm,
    ValidationCustomizedInputField,
    ValidationSlider
  },
  data() {
    return {
      text: "",
      status: false,
      gpioParams: {
        logic: [
          { value: 0, text: "Нет логики" },
          { value: 1, text: "Индикация заблокированного состояния" },
          { value: 2, text: "Индикация разблокировки" },
          { value: 3, text: "Внешняя звуковая индикация" },
          { value: 4, text: "Сигнал открытия" },
          { value: 5, text: "Кнопка разблокировки входа" },
          { value: 6, text: "Кнопка разблокировки выхода" },
          // { value: 7, text: "Обратная связь состояния замка" },
          { value: 8, text: "Пожарная сигнализация" }
        ],
        signal_type: [
          { value: 0, text: "Постоянный" },
          { value: 1, text: "Периодический" }
        ],
        signal_type_2: [
          { value: 0, text: "Без восстановления работоспособности" },
          { value: 1, text: "С восстановлением работоспособности" }
        ],
        signal_type_3: [
          { value: 0, text: "Одиночный" },
          { value: 1, text: "Периодический" }
        ]
      },
      directions: [
        {
          text: "Вход",
          value: "in"
        },
        {
          text: "Выход",
          value: "out"
        },
        {
          text: "В обе стороны",
          value: "both"
        }
      ],
      active_levels: [
        {
          value: 1,
          text: "Высокий"
        },
        {
          value: 0,
          text: "Низкий"
        }
      ]
    };
  },
  computed: {
    gpioConfigSettings() {
      return this.$store.getters.getGpioSettings;
    },
    controllerSettings() {
      return this.$store.getters.getControllerInfoById;
    },

    controllerHash() {
      return this.$store.getters.getControllerInfoHashById;
    },
    urlQuery() {
      return {
        query: {},
        id: this.$route.params.id
      };
    }
  },
  created() {
    this.$store.commit("CLEAR_CONTROLLERHASH_SETTINGS");
    this.$store.commit("CLEAR_CONTROLLER_SETTINGS");
    this.$store.commit("CLEAR_GPIO_DATA");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: this.urlQuery.query
    });
    this.$store.dispatch("getControllerSettings");
    this.$store.dispatch("getControllerHash");
  },
  methods: {
    async resetHash() {
      this.$store.dispatch("deleteHash", { id: this.urlQuery.id }).then(() => {
        this.$store.dispatch(
          "successNotification",
          actionMessage.remove(successMessageDictionary.hash)
        );
        this.$store.dispatch("getControllerHash");
      });
    },
    async searchPrimeFirm(val) {
      return (
        await getControllerFirmwaresByIdsRequest({
          query: { search: val, chip: "ESP", ordering: "-id", is_active: true },
          id: this.urlQuery.id
        })
      ).data.results.map(el => ({
        text: `${el.id}, ${el.chip || ""} ${el.version || ""}` || "",
        value: el.id
      }));
    },
    async searchSecondFirm(val) {
      return (
        await getControllerFirmwaresByIdsRequest({
          query: { search: val, chip: "NRF", ordering: "-id", is_active: true },
          id: this.urlQuery.id
        })
      ).data.results.map(el => ({
        text: `${el.id}, ${el.chip || ""} ${el.version || ""}` || "",
        value: el.id
      }));
    },
    async updateSecondaryFirmware() {
      let data = {
        secondary_firmware: this.controllerSettings.secondary_firmware
      };

      updateControllersSecondaryFirmware({
        id: this.urlQuery.id,
        data
      }).then(() => {
        setTimeout(
          () =>
            this.$store.dispatch(
              "successNotification",
              `Настройки применены! ${
                this.wsMessages[0]
                  ? "\n" + this.wsMessages[this.wsMessages.length - 1].text
                  : ""
              }`
            ),
          1000
        );
      });
    },
    async updatePrimeFirmware() {
      let data = {
        primary_firmware: this.controllerSettings.primary_firmware
      };

      updateControllersPrimaryFirmware({
        id: this.urlQuery.id,
        data
      }).then(() => {
        setTimeout(
          () =>
            this.$store.dispatch(
              "successNotification",
              `Настройки применены! ${
                this.wsMessages[0]
                  ? "\n" + this.wsMessages[this.wsMessages.length - 1].text
                  : ""
              }`
            ),
          1000
        );
      });
    },
    updateBluetoothSection() {
      this.updatePartial([
        "name",
        "primary_mac",
        "secondary_mac",
        "trigger_level",
        "directions",
        "hands_free"
      ]);
    },
    updateLockSection() {
      const fields = ["lock_connection_type", "lock_door_opened_time"];

      let updateFields = {};
      fields.map(f => (updateFields[f] = this.controllerSettings[f]));
      setLockConfigRequest({
        id: this.urlQuery.id,
        data: updateFields
      }).then(() => {
        setTimeout(
          () =>
            this.$store.dispatch(
              "successNotification",
              `Настройки применены! ${
                this.wsMessages[0]
                  ? "\n" + this.wsMessages[this.wsMessages.length - 1].text
                  : ""
              }`
            ),
          1000
        );
      });
    },
    updateInterfaceSection() {
      const fields = [
        "wiegand1",
        "wiegand2",
        "wiegand1_direction",
        "wiegand2_direction",
        "rs1",
        "rs2"
      ];
      let updateFields = {};
      fields.map(f => (updateFields[f] = this.controllerSettings[f]));
      setExternalInterfacesRequest({
        id: this.urlQuery.id,
        data: updateFields
      }).then(() => {
        setTimeout(
          () =>
            this.$store.dispatch(
              "successNotification",
              `Настройки применены! ${
                this.wsMessages[0]
                  ? "\n" + this.wsMessages[this.wsMessages.length - 1].text
                  : ""
              }`
            ),
          1000
        );
      });
    },
    updatePartial(fields) {
      let updateFields = {};
      fields.map(f => (updateFields[f] = this.controllerSettings[f]));

      patchControllerByIdRequest({
        id: this.urlQuery.id,
        data: updateFields
      }).then(() => {
        setTimeout(
          () =>
            this.$store.dispatch(
              "successNotification",
              `Настройки применены! ${
                this.wsMessages[0]
                  ? "\n" + this.wsMessages[this.wsMessages.length - 1].text
                  : ""
              }`
            ),
          1000
        );
      });
    },
    updateGpio(item) {
      const id = this.urlQuery.id;
      if (
        typeof item.number === "number" &&
        item.logic !== null &&
        item.level_of_one !== null &&
        item.signal_type !== null
      )
        setGpioConfigRequest({
          id: id,
          data: item
        }).then(() => {
          setTimeout(
            () =>
              this.$store.dispatch(
                "successNotification",
                `Настройки применены! ${
                  this.wsMessages[0]
                    ? "\n" + this.wsMessages[this.wsMessages.length - 1].text
                    : ""
                }`
              ),
            1000
          );
        });
    },
    checkActiveLevel(item) {
      if ([5, 6].includes(item.logic)) {
        return true;
      } else {
        return false;
      }
    },
    portsList(elem) {
      if ([0, 1, 2].includes(elem.number)) {
        return this.gpioParams.logic.filter(
          el => ![5, 6, 8].includes(el.value)
        );
      }
      return this.gpioParams.logic;
    },
    uploadUserAccesses() {
      this.loading = true;
      this.$store.commit("setRouterQuery", {
        routes: this.$route.params
      });
      this.$store
        .dispatch("uploadUserAccesses")
        .then(() => {
          setTimeout(
            () =>
              this.$store.dispatch(
                "successNotification",
                `Настройки применены! ${
                  this.wsMessages[0]
                    ? "\n" + this.wsMessages[this.wsMessages.length - 1].text
                    : ""
                }`
              ),
            1000
          );
        })
        .catch(error => {
          this.$store.commit("SET_ERROR_NOTIFICATION", {
            errors: [
              {
                field_verbose: "Ошибка",
                message: `${error.response.data.message}`
              }
            ]
          });
        })
        .finally(() => (this.loading = false));
    },
    uploadCardsAccesses() {
      this.loading = true;
      this.$store.commit("setRouterQuery", {
        routes: this.$route.params
      });
      this.$store
        .dispatch("uploadCardAccesses")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.upload(successMessageDictionary.childrens)
          );
        })
        .catch(error => {
          this.$store.commit("SET_ERROR_NOTIFICATION", {
            errors: [
              {
                field_verbose: "Ошибка",
                message: `${error.response.data.message}`
              }
            ]
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style>
.settings__input {
  display: none;
}

.hash {
  margin-left: 30px;
}

.hashResolve {
  margin-left: 0px !important;
}

.validation-input-field span {
  width: 100%;
}

.validation-input-field div {
  box-shadow: unset;
  background: unset;
}

.validation-input-field .v-input__slot {
  background: unset;
  box-shadow: none;
  padding: 0 !important;
  background: unset !important;
}

.validation-input-field .v-input__slot fieldset {
  border: unset;
}

.validation-input-field .v-text-field__details,
.validation-select-field .v-text-field__details {
  position: absolute;
  bottom: -10px;
  left: 0;
}

.validation-input-field .v-label,
.validation-select-field .v-label {
  padding: 0 !important;
  color: #6d778e !important;
}

.validation-select-field {
  width: 100%;
}

.validation-select-field .v-select__slot {
  padding: 0;
}

.validation-input-field .v-text-field--outlined.v-input--dense .v-label--active,
.validation-select-field
  .v-text-field--outlined.v-input--dense
  .v-label--active,
.validation-select-field .v-label {
  transform: translateY(-13px);
}
</style>

<style lang="scss" scoped>
.input-field {
  &__progress {
    &::after {
      display: none;
    }

    div {
      height: 100%;
      position: absolute;
      background-color: #95c23d;
      border-radius: 80px;
    }
  }
}

.v-expansion-panel::before {
  box-shadow: 0 1px 3px rgba(37, 65, 106, 0.1) !important;
}
</style>
<style scoped>
.navbar__btn-wrap {
  margin-left: 25px;
}
@media screen and (max-width: 650px) {
  .navbar__btn-wrap {
    margin-left: 0px;
  }
  .hash {
    margin-left: 0px;
  }
}
</style>
