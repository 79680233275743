<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <InputField
      :value="value"
      :disabled="disabled"
      :type="type"
      :mask="mask"
      :label="label"
      :errors="errors"
      :required="required"
      @input="$emit('input', $event)"
      :counter="counter"
    >
      <slot></slot>
    </InputField>
  </ValidationProvider>
</template>

<script>
import InputField from "@/components/fields/InputField";
export default {
  name: "ValidationInputField",
  components: { InputField },
  props: {
    mask: {
      type: String
    },
    type: {
      type: String
    },
    rules: {
      type: String
    },
    validateName: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    counter: {
      type: [Boolean, Number, String]
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [Number, String]
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  computed: {
    name() {
      return this.validateName || this.label;
    }
  }
};
</script>

<style scoped></style>
