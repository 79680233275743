<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <BaseSlider
      :label="label"
      :value="value"
      :minValue="minValue"
      :maxValue="maxValue"
      :errors="errors"
      :disabled="disabled"
      @input="$emit('input', $event)"
    />
  </ValidationProvider>
</template>

<script>
import BaseSlider from "@/components/fields/BaseSlider";
export default {
  name: "ValidationInputField",
  components: { BaseSlider },
  props: {
    rules: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String
    },
    minValue: {
      type: String,
      required: true
    },
    maxValue: {
      type: String,
      required: true
    },
    errors: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String]
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  computed: {
    name() {
      return this.validateName || this.label;
    }
  }
};
</script>

<style scoped></style>
